.wrapper {
    background-color: #f4f5f8;
    height: 100vh;
}
.form-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f5f8;
    padding: 70px 0;
    margin: 0;
  }
  .row-form {
    width: 400px;
    box-shadow: 0 0 5px rgba(0,0,0,.1);
    background: #fff;
    padding: 20px 0;
  }
  .user {
    display: flex;
    border: 1px solid #7d7d7d;
    font-size: 30px;
    padding: 20px;
    border-radius: 50%;
    vertical-align: middle;
    color: #033e3e;
    width: 70px;
    height: 70px;
    max-width: 100%;
    margin: 0 auto;
  }
  .welcome-text {
    margin-top: 9px;
  }
  .welcome-text h2 {
    font-size: 24px;
    color: #033e3e;
    text-align: center;
    margin: 0 0 20px;
    text-transform: uppercase;
    font-family: "Roboto",sans-serif;
  }
  .form-style {
    padding: 14px 15px 3px;
  }
  .form-style input {
    border: 0;
    border-radius: 0;
    margin-left: 5px;
    margin-right: 4px;
  }                             
  .form-ok {
    padding: 8px .75rem !important;
    background: #f3f1f1 !important;
  }            
  .form-registration{
    display: flex;
    margin: 12px 0 0;
    background: #f3f1f1 !important;
    border: 1px solid #ddd;
  }  
  .btn-imp .ew-primary-col {
    background: #dc0d5b !important;
    border-radius: 0 !important;
    border-color: #fff !important;
  }
  .btn-imp .ew-primary-col-text {
    color: #fff !important;
    padding: 10px 0;
  }  
  .err-container {
    margin-top: 10px;
    height: 10px;
  } 
  .errp {
    text-align: center;
    color: red;
    font-size: 14px;
  }   
  .succ-container {
    margin-top: 10px;
    height: 10px;
  } 
  .succp {
    text-align: center;
    color: green;
    font-size: 14px;
  }  

/* -->> OTP Verification css <<--*/ 
  .form-verification{
    display: flex;
    margin: 12px 0 0;
    background: #f3f1f1 !important;
    border: 1px solid #ddd;
  }  
  .form-wrap-verf{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f5f8;
    padding: 115px 0;
    margin: 0;
  }                                                                                                                                                      