.v-container {
    height: calc(100vh - 174px);
    margin: 10px;
    background: #fff;
  }
  .v-containerinner{
    padding: 12px;
    height: 100%;
  }
  .v-containerinner .v-select {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 12px;
  }
  .v-containerinner .v-select h2 {
    font-size: 15px;
    font-family: var(--font-medium);
    text-transform: capitalize;
    width: 20%;
    margin: 0;
    color: #3c4858;
  }
  .v-containerinner .v-select select{
    max-width: 280px;
    font-size: 14px;
  }
  .v-browse {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

  }
  .v-browse input{
    font-size: 14px;
    max-width: 278px;
    margin-right: 12px;
    padding: 6px 12px;
  }
  .v-browse button{
    font-size: 13px !important;
    color: #fff !important;
    padding: 7px 24px !important;
    background: #dc0d5b;
    border: none;
  }
  .file {
    display: flex;
    align-items: center;
  }
  .file p{
    margin: 0;
    padding: 5px;
    margin-right: 5px;
  }
  .file p svg{
    font-size: 22px;
    color: #dc0d5b;
    cursor: pointer;
    } 
    .calendertopcont {
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease;
      position: absolute;
      z-index: 5;
      transform: translate(-50%);
      left: 50%;
    }
    .calendertopcont.active {
      opacity: 1;
      visibility: visible;
      transition: all .3s ease;
      transform: translate(-50%);
      left: 50%;
    }
    .calenderoverlay {
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .2;
      z-index: 2;
    }
    .calenderoverlay.active2 {
      visibility: visible;
      transition: all .3s ease;
      background: #Fff;
      opacity: .9;
    }
    .rdrDateRangePickerWrapper {
      box-shadow: 0 2px 5px 1px rgba(7, 7, 7, 0.16);
      border: 1px solid #ccc;
    }
  


    .rdrInputRange {
      padding: 5px 12px !important;
    }
    
    .rdrStaticRangeLabel {
      padding: 10px 12px !important;
    }
    
    .rdrDateDisplay {
      margin: 8px 10px 8px !important;
    }
    
    .rdrMonthAndYearWrapper {
      height: 42px !important;
      padding-top: 5px !important;
    }
    
    .rdrMonthName {
      padding: 8px 10px 8px !important;
    }
    
    .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
      width: 180px !important;
    }
    
    .rdrCalendarWrapper.rdrDateRangeWrapper {
      width: 480px;
    }
    
    .rdrDateDisplayItem input {
      height: 28px !important;
    }
    
    .rdrMonth {
      padding-bottom: 6px !important;
    }
    
    .rdrDefinedRangesWrapper {
      font-size: 11px !important;
    }
    
    .topmenusclr {
      display: flex;
      align-items: start;
      justify-content: space-between;
      width: 85%;
    }
    
    .rdrCalendarWrapper .rdrDateDisplay {
      width: 90%;
      margin: 8px 5px !important;
    }
    
    .calendericontick {
      position: absolute;
      right: 6px;
      top: 9px;
      background: #dc0d5b;
      width: 29px;
      height: 29px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border:none;
    }
    
    .calendericontick svg {
      color: #fff;
      font-size: 20px;
    }
    .loader{
      width: 55px;
    }
    .ew-loader {
      height: calc(100vh - 85px);
    }

    .errdiv {
      position: absolute;
      text-align: center;
      left: 50%;
      transform: translate(-50%);
    }
    .errdiv .errmsg{
      margin: 0;
      text-transform: capitalize;
      font-size: 14px;
      color: red;
      background: #f9eded;
      padding: 3px 12px;
    }
    .errdiv .successmsg{
      margin: 0;
      text-transform: capitalize;
      font-size: 14px;
      color: green;
      background: #d8ffd8b7;
      padding: 3px 12px;
    }
    .v-browse p{
      margin: 0;
      margin-left: 8px;
      position: relative;
    }
    .v-browse p svg{
      font-size: 20px;
      color: #dc0d5b;
      cursor: pointer;
    }
    .v-browse p span{
      position: absolute;
      min-width: 350px;
      top: 0;
      left: 0;
      width: 100%;
      display: none;
      transition: all .5s ease;
      
    }
    .v-browse p:hover span{
      display: block;
    }
    .v-select input{
      max-width: 280px;
      padding: 4px 10px;
      font-size: 14px;
    }
    .v-Occasion input{
        max-width: 280px;
        padding: 4px 10px;
        font-size: 14px;
    }
    .v-containerinner .v-Occasion h2 {
        font-size: 15px;
        font-family: var(--font-medium);
        text-transform: capitalize;
        width: 20%;
        margin: 0;
        color: #3c4858;
      }
      .v-containerinner .v-Occasion {
        display: flex;
        align-items: center;
        width: 50%;
        margin-bottom: 12px;
      }
      .v-To input{
        max-width: 280px;
        padding: 4px 10px;
        font-size: 14px;
    }
    .v-containerinner .v-To h2 {
        font-size: 15px;
        font-family: var(--font-medium);
        text-transform: capitalize;
        width: 20%;
        margin: 0;
        color: #3c4858;
      }
      .v-containerinner .v-To {
        display: flex;
        align-items: center;
        width: 50%;
        margin-bottom: 12px;
      }



      .v-From input{
        max-width: 280px;
        padding: 4px 10px;
        font-size: 14px;
    }
    .v-containerinner .v-From h2 {
        font-size: 15px;
        font-family: var(--font-medium);
        text-transform: capitalize;
        width: 20%;
        margin: 0;
        color: #3c4858;
      }
      .v-containerinner .v-From {
        display: flex;
        align-items: center;
        width: 50%;
        margin-bottom: 12px;
      }
      .v-Message textarea{
        max-width: 280px;
        padding: 4px 10px;
        font-size: 14px;
    }
    .v-containerinner .v-Message h2 {
        font-size: 15px;
        font-family: var(--font-medium);
        text-transform: capitalize;
        width: 20%;
        margin: 0;
        color: #3c4858;
      }
      .v-containerinner .v-Message {
        display: flex;
        align-items: center;
        width: 50%;
        margin-bottom: 12px;
      }
     