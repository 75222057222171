.footer {
    align-items: center;
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: #fff;
    font-size: 12px;
    border-top: 1px solid #d4d1d1;
    z-index: 99;
  }
  .footer {
    height: 35px !important;
  }
  .ewp-footer {
    padding: 0 1.5rem !important;
  }
  .inner-footer {
    justify-content: space-between;
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  .testtt {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999999999;
    width: 60px;
    background: #fff;
  }
  .image-container img {
    width: 32px;
    width: 28px;
    height: 100%;
    z-index: 999;
    top: 2px;
    right: 8px;
    border: 1px solid #959595;
    border-radius: 50%;
    box-shadow: 0 19px 38px rgba(0,0,0,.3),0 15px 12px rgba(0,0,0,.22);
  }
  .image-container .caption-4 {
    right: -90px;
    transition-duration: 1.5s;
    transition-timing-function: linear;
    top: 8px;
    visibility: hidden;
    z-index: -99;
  }
  .image-container .image-caption {
    position: absolute;
    background-color: #fff;
    text-align: center;
  }
  .image-container .caption-4 {
    right: -110px;
    transition: all 1.8s;
      transition-duration: 1.8s;
      transition-timing-function: ease;
  }
  .image-caption p {
    font-size: 14px;
    color: #000;
    font-weight: 700;
  }
  .image-container:hover .caption-4 {
    right: 65px;
    transition: all 1.8s;
    visibility: visible;
    z-index: -99;
  }