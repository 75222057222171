@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
    url("../src/fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"),
    url("../src/fonts/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "RobotoMedium";
  src: local("RobotoMedium"),
    url("../src/fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
    url("../src/fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

:root {

  --theme-color: #c2185b;
  --font-light: 'RobotoLight', sans-serif;
  --font-medium: 'RobotoMedium', sans-serif;
  --font-regular: 'RobotoRegular', sans-serif;
  --font-bold: 'RobotoBold', sans-serif;
}

body {
  font-family: var(--font-regular);
}

.customcont {
  background: #f4f5f8;
  height: calc(100vh - 34px);
  position: relative;
}

.headingsecinner h2 {
  font-size: 24px;
  font-family: var(--font-medium);
  margin: 0;
  color: #3c4858;
}
.headingsecinner button{
  font-size: 13px !important;
  color: #fff !important;
  padding: 7px 24px !important;
  background: #dc0d5b;
  border: none;
}
.headingsecinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
}
.headingsec {
  background: #fff;
  margin: 10px;
}
.ag-paging-panel{
  background: #f4f5f8 !important;
}
.file button {
  margin-right: 6px;
}
.loadingtrash{
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbfbfbd9;
}
.loadingtrash img{
  width: 48px;
}
.loaderagrid img{
  width: 40px !important;
}
.btnpopp{
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnpopp svg{
  background: none;
  border: none;
  font-size: 22px;
  display: block;
  color: #dc0d5b;
}
.modal-body{
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-body{
  display: flex;
  align-items: center;
  justify-content: center;
}
.videopopupsec {
  width: 500px;
  height: calc(100vh - 95px);
}
.loaderafter{
  position: absolute;
  z-index: 999;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
}
.loaderafter img{
  width: 46px;
}
.ag-cell-pencileditico{
  cursor: pointer;
}
.datefrmto p {
  margin: 0;
  font-size: 13px;
  font-family: var(--font-medium);
  margin-right: 8px;
}
.filedatecldr .filep {
  display: flex;
  align-items: center;
}
.filedatecldr .filep svg{
  font-size: 22px;
  color: #dc0d5b;
  cursor: pointer;
}
.bsckreports {
  margin-left: 15px;
}
.bsckreports button a {
  color: #fff;
  text-decoration: none;
  padding: 7px 24px;
  display: block;
}
.bsckreports button {
  padding: 0 !important;
}
.ag-header-cell-text {
  font-size: 14px;
  font-family: var(--font-regular);
  color: #3c4858;
}
.ag-cell-value {
  font-size: 14px;
  font-family: var(--font-regular);
  color: #3e4154;
}
.calendrico{
  cursor: inherit !important;
}
.permloading img {
  width: 60px;
}
.permloading {
  background: #fff;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.unautmessagewrap{
  background: #fff;
  height: calc(100vh - 170px);
  margin: 0 10px;
}
.unautmessage {
  display: flex;
  justify-content: center;
  padding-top: 48px;
  font-size: 17px;
  color: #3c4858 ;
}
.usernamear{
  display: flex;
  align-items: center;
}
.usernameid h2 {
  font-size: 15px;
  margin: 0;
  font-family: var(--font-medium);
  margin-right: 10px;
  color: #3c4858;
}