.v-first-name input{
    max-width: 280px;
    padding: 4px 10px;
    font-size: 14px;
}
.v-containerinner .v-first-name h2 {
    font-size: 15px;
    font-family: var(--font-medium);
    text-transform: capitalize;
    width: 18%;
    margin: 0;
    color: #3c4858;
  }
  .v-containerinner .v-first-name {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 12px;
  }
  .v-last-name input{
    max-width: 280px;
    padding: 4px 10px;
    font-size: 14px;
}
.v-containerinner .v-last-name h2 {
    font-size: 15px;
    font-family: var(--font-medium);
    text-transform: capitalize;
    width: 18%;
    margin: 0;
    color: #3c4858;
  }
  .v-containerinner .v-last-name {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 12px;
  }
  .v-email input{
    max-width: 280px;
    padding: 4px 10px;
    font-size: 14px;
}
.v-containerinner .v-email h2 {
    font-size: 15px;
    font-family: var(--font-medium);
    text-transform: capitalize;
    width: 18%;
    margin: 0;
    color: #3c4858;
  }
  .v-containerinner .v-email {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 12px;
  }
  .v-username input{
    max-width: 280px;
    padding: 4px 10px;
    font-size: 14px;
}
.v-containerinner .v-username h2 {
    font-size: 15px;
    font-family: var(--font-medium);
    text-transform: capitalize;
    width: 18%;
    margin: 0;
    color: #3c4858;
  }
  .v-containerinner .v-username {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 12px;
  }
  .v-password input{
    max-width: 280px;
    padding: 4px 10px;
    font-size: 14px;
}
.v-containerinner .v-password h2 {
    font-size: 15px;
    font-family: var(--font-medium);
    text-transform: capitalize;
    width: 18%;
    margin: 0;
    color: #3c4858;
  }
  .v-containerinner .v-password {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 12px;
  }
  .accordion-item {
    padding: 0 !important;
    margin-top: 10px;
    width: 70%;
    border: 1px solid #c5c9cc;
  }
  .accordion-button.btn {
    color: #3c4858; 
     font-size: 16px; 
    font-family: var(--font-medium);
    text-transform: capitalize;
  }
  .errordiv {
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translate(-50%);
  }

  .errordiv .errormsg{
    margin: 0;
    text-transform: capitalize;
    font-size: 14px;
    color: red;
    background: #f9eded;
    padding: 3px 12px;
  }
  .succcessdiv {
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translate(-50%);
  }
  .succcessdiv .succmsg{
    margin: 0;
    text-transform: capitalize;
    font-size: 14px;
    color: green;
    background: #d8ffd8b7;
    padding: 3px 12px;
  }
  .dropicon{
    position: absolute;
    right: 0px;
    font-size: 35px;
   top: -3px;
  }
  .accordion-body {
    padding: 5px 10px 0px;
    color: #212529;
  }
  .accordion-button:focus{
    box-shadow: none !important;
  }
  .select-acc {
    padding: 10px 5px 10px;
  }
  .selectlabel {
    margin-left: 5px;
  }
  .accordion-button:not(.collapsed){
    background-color:#ffffff;
  }
  .accordion-button:not(.collapsed)::after{ 
  color: black;
  }








  
 