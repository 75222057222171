.team.anchor {
    font-size: 13px !important;
color: #fff !important;
padding: 7px 24px !important;
background: #dc0d5b;
border: none;
text-decoration: none;
text-transform: capitalize;
  }
  /* .userpencilicon{
    cursor: pointer;
   opacity: 0;
  }
  .userpencilicon:hover{
    opacity: 1;
  } */
  .teamvcont{
    height: calc(100vh - 172px);
    margin: 10px;
    background: #fff;
    overflow: auto;
    padding-bottom: 10px;
  }
  .vteamcontinn{
    height: auto !important;
  }
  .accordion-item {
    border: 1px solid #ccc !important;
    width: 80%;
  }
  .accodcsbtn{
    font-size: 15px;
    font-family: var(--font-medium);
    text-transform: capitalize;
    margin: 0;
    color: #3c4858;
  }
  .selectaccordv{
    margin: 5px 0px ;
  }
  .colaccordv {
    margin-bottom: 5px;
  }
  .accordion-button:not(.collapsed){
    color:#3c4858 !important ;
  }