.navbar {
    padding: 0 !important;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
}
.logo {
  width: 150px;
}
.navbarinner {
    padding: 8px 0 !important;
}
.navwrap {
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;
    margin-left: 20px;
  }
  .navrightsec {
    display: flex;
    align-items: center;
  }
  .navwrap li {
    list-style: none;
    font-size: 14px;
  }
  .navwrap li a{
    color: #282a2d;
    text-decoration: none;
  }
  .dropdown-menu{
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  .dropdown-menu.show {
    position: absolute;
    top: 48px;
    left: -102px;
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 50px 100px rgb(50 50 93/10%),0 15px 35px rgb(50 50 93/6%),0 5px 15px rgb(0 0 0/6%);
    border-color: #f4f4f4;
    padding: 2px 0;
  }
  .navwrap li a {
    color: #282a2d;
    text-decoration: none;
    display: block;
    padding: 4px 8px;
    font-size: 13px;
    font-family: var(--font-regular);
  }
  .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
  }
  .dropdown-toggle {
    background: transparent;
    border: none;
    font-size: 14px;
    font-family: var(--font-regular); 
  }
  .navwrap li:hover a {
    background-color: #f4f4f4;
  }
  .dropdownswrap {
    display: flex;
    align-items: center;
  }
  .dropdownswrap .dropdown {
    margin-left: 15px;
  }
  .dropdownswrap .dropdown-user {
    margin-left: 30px;
  }
  .addteammember {
    margin: 0 10px;
  }
  .addteammember a{
    font-size: 14px;
  color: #3c4858;
  text-decoration: none;
  }
  .addteammember a:hover{
    color: #3c4858;
  }
  .reportsheader {
    margin-left: 10px;
  }
  .reportsheader a{
    font-size: 14px;
  color: #3c4858;
  text-decoration: none;
  }
  .reportsheader a:hover{
    color: #3c4858;
  }
  
  .dropdownswrap .dropdown .btn {
    background: transparent;
    border: none;
    font-size: 14px;
    color: #3c4858;
  }
  .dropdownswrap .dropdown .btn:first-child:active{
    background-color: transparent !important;
    color: #3c4858 !important;
  }
  .dropdownswrap .dropdown .dropdown-menu{
    left: -95px;
    padding: 0;
    top: 50px !important; 
  }
  .dropdown-user .dropdown-toggle::after{
    display: none;
  }
  .dropdown-user .btn {
    border: 1px solid #3c4858 !important;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdown-user .btn svg {
    font-size: 14px;
  }
  .dropdown-menu li {
    font-size: 14px;
    padding: 2px 0;
    cursor: pointer;
  }
  .dropdown-menu li:hover {
    background-color: rgba(231,239,239,.8392156862745098);
  }
  .dropdown-menu li a {
    padding: 5px 8px;
    font-size: 13px;
    display: block;
    color: #3c4858;
    text-decoration: none;
  }
  .useralt{
    position: relative;
  }